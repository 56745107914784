
import Vconsole from "vconsole";
import { App } from "vue";  
export default function useConsole(app: App){
  console.log(app);
  if(import.meta.env.MODE=='development'){
   const vconsole = new Vconsole()
   app.use(vconsole as any)
    }else{
   console.warn('This Vconsole runs in the development environment ')
  }
}