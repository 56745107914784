import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/zuzuya',
    name: '首页',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zuzuya.vue')
  },
  {
    path: '/xyz-zjgl',
    name: '租机攻略',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/xyz-zjgl.vue')
  },
  {
    path: '/xyz-xdky',
    name: '新店开业 特惠来袭',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/xyz-xdky.vue')
  },
  {
    path: '/xyz-kxzb',
    name: '开学添装备 助力新学期',
    component: () => import(/* webpackChunkName: "about" */ '../views/xyz-kxzb.vue')
  },
  {
    path: '/xyz-kxzbs',
    name: '电脑签收验机流程',
    component: () => import(/* webpackChunkName: "about" */ '../views/xyz-kxzbs.vue')
  },
  {
    path: '/xyz-kxzbe',
    name: '租电脑必看攻略',
    component: () => import(/* webpackChunkName: "about" */ '../views/xyz-kxzbe.vue')
  },
  {
    path: '/xyz-fjtz-activity',
    name: '春节放假通知',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/xyz-fjtz.vue')
  },
  {
    path: '/xyz-tqcy-activity',
    name: '踏青出游 不负春光',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/xyz-tqcy.vue')
  },
  {
    path: '/xyz-lqyhj-activity',
    name: '绑定账号领取 社群专属优惠券',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/xyz-lqyhj.vue')
  },
  {
    path: '/xyz-lqyhjcz-activity',
    name: '绑定账号领取 社群专属优惠券(长租)',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/xyz-lqyhjcz.vue')
  },
  {
    path: '/pdfShow-activity',
    name: '文件预览',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/pdfShow.vue')
  },
]



const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name as string ||'首页'
  next()
})

export default router
