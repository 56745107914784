import { createApp } from 'vue'
import './reset.css'
import './libs/flexible.js'
import App from './App.vue'
import router from './router'
import 'animate.css/animate.min.css'
import useConsole from "./plugins/vConsolePlgins"
// sweetalert

const app = createApp(App)
app.use(router)
app.use(useConsole)
app.mount('#app')
// import vconsole from './vconsole';
// createApp(App).use(router,vconsole).mount('#app')

